import React, { useCallback } from 'react';
import { OutcomeIcon } from '../assets/icons/Outcome';
import { FormValues } from '../types';
import { initialValues } from '../utils/form';

interface useFormProps {
  values: FormValues;
  setValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

export const useForm = ({ values, setValues }: useFormProps) => {
  const initialState = {
    acceptReservations: false,
    checkedDays: [],
    openingTimes: [],
    openingExceptions: [],
  };

  const resetValues = useCallback(() => {
    setValues(initialValues);
  }, [setValues]);

  const handleChangeValues = useCallback(
    e => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value });
    },
    [setValues, values]
  );

  const popupContent = {
    success: {
      backgroundColor: 'bg-primary',
      content:
        'La sua prenotazione è stata inviata con successo! Appena verrà accettata, riceverà la conferma via mail.',
      icon: <OutcomeIcon outcome="success" />,
    },
    error: {
      backgroundColor: 'bg-dark',
      content: (
        <>
          Qualcosa è andato storto...
          <br />
          Riprova più tardi
        </>
      ),
      icon: <OutcomeIcon outcome="error" />,
    },
  };

  return {
    initialValues,
    initialState,
    resetValues,
    handleChangeValues,
    popupContent,
  };
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout/Layout";
import { Button } from "../components/ui/Button";
import { download } from "../components/utilities/download";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import { usePopup } from "../hooks/usePopup";
import { convertTimesToDecimal, num2Time } from "../utils/dates";
import { ShopData } from "../types";
import { useForm } from "../hooks/useForm";
import { initialValues } from "../utils/form";

const today = new Date();

const BASE_URL = "https://admin.ristoranteamangiare.it/wp-json";
const MAIL_API = `${BASE_URL}/contact-form-7/v1/contact-forms/242/feedback`;
const STATUS_API = `${BASE_URL}/shop-status/v1/shop-status`;

const Prenota = () => {
  const [values, setValues] = useState(initialValues);
  const { initialState, resetValues, handleChangeValues, popupContent } =
    useForm({
      values,
      setValues,
    });
  const [data, setData] = useState<ShopData>(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [outcome, setOutcome] = useState("success");
  const [isSending, setIsSending] = useState(false);

  const isDisabled = isSending || !isOpen;

  /**
   * Exceptional closure
   * We use this exceptionally to add closing times for the selected range
   */
  const exceptionalClosure = true;
  const addClosingRange = (closures, startDate, endDate) => {
    for (
      let d = new Date(startDate);
      d <= new Date(endDate);
      d.setDate(d.getDate() + 1)
    ) {
      closures.push({
        date: d.toISOString().split("T")[0],
        from: "12:00",
        to: "23:35",
        is_open: false,
      });
    }
  };

  const checkIsOpen = useCallback(async () => {
    try {
      const res = await axios.get(STATUS_API);
      if (exceptionalClosure) {
        addClosingRange(
          res?.data?.openingExceptions,
          "2023-08-07",
          "2023-08-29"
        );
      }
      setData(res.data);
      setIsOpen(res.data.acceptReservations === "1");
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [exceptionalClosure]);

  useEffect(() => {
    checkIsOpen();
  }, [checkIsOpen]);

  const { PopupComponent } = usePopup({
    isOpen: isPopupOpen,
    headerClass: popupContent[outcome]?.backgroundColor,
    bodyClass: popupContent[outcome]?.backgroundColor,
    onClose: () => setIsPopupOpen(false),
    content: (
      <div className="d-flex flex-column text-white">
        <p
          style={{ maxWidth: "700px" }}
          className="text-uppercase h2 text-center ps-2 pe-2 lh-60 mx-auto"
        >
          {popupContent[outcome]?.content}
        </p>
        {outcome === "success" && (
          <p className="text-center h3">(controlla anche le SPAM)</p>
        )}
        <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
          {popupContent[outcome]?.icon}
        </div>
      </div>
    ),
  });

  registerLocale("it", it);

  const sendEmail = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) => {
      setIsSending(true);
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (key === "date") {
          formData.append(key, values[key].toLocaleDateString("it-IT"));
        } else {
          formData.append(key, values[key]);
        }
      });

      await axios
        .post(MAIL_API, formData, {
          headers: {
            "Content-Type": `multipart/form-data`,
            Accept: `application/json`,
          },
        })
        .then(res => {
          if (res.data?.status === "mail_sent") {
            setOutcome("success");
            setIsPopupOpen(true);
          } else {
            setOutcome("error");
            setIsPopupOpen(true);
          }
        })
        .catch(err => {
          setOutcome("error");
          setIsPopupOpen(true);
          console.log(err);
        });
      setIsSending(false);
      resetValues();
    },
    [resetValues]
  );

  const handleOnSubmit = useCallback(
    e => {
      e.preventDefault();
      sendEmail(values);
    },
    [sendEmail, values]
  );

  /**
   * Check if the day has times
   * @param date
   * @returns boolean
   **/
  const hasTimes = useCallback(
    (date: Date) => {
      if (isLoaded && isOpen) {
        if (data?.openingExceptions?.length > 0) {
          const dateString = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
          const match = data.openingExceptions.find(
            exception => exception.date === dateString
          );
          if (match) {
            return match.is_open;
          }
        }
        const day = date.getDay();
        return data.checkedDays.includes(day);
      }
      return false;
    },
    [data.checkedDays, data.openingExceptions, isLoaded, isOpen]
  );

  /**
   * Generate times for selected day
   * @param start
   * @param end
   * @returns JSX.Element[]
   */
  const generateTimes = useCallback((start: number, end: number) => {
    const times: JSX.Element[] = [];
    for (let i = start; i <= end; i += 0.5) {
      times.push(
        <option key={`time-${i}`} value={i}>
          {num2Time(i)}
        </option>
      );
    }
    return times;
  }, []);

  /**
   * Get times for selected day
   * @returns JSX.Element[]
   **/
  const getTimes = useCallback(() => {
    const selectedTime = values.date;
    const day = selectedTime.getDay();
    if (data?.openingExceptions?.length > 0) {
      const dateString = selectedTime.toISOString().split("T")[0];
      const match = data.openingExceptions.find(
        exception => exception.date === dateString
      );
      if (match) {
        if (match.is_open) {
          const { from, to } = match;
          const toDec = convertTimesToDecimal([from, to]);
          const times = generateTimes(toDec[0], toDec[1]);
          return times;
        }
        return [];
      }
    }
    const filteredTimes = data?.openingTimes?.filter(time =>
      time.on_days.includes(day)
    );
    if (filteredTimes.length > 0) {
      const times: JSX.Element[] = [];
      for (let i = 0; i < filteredTimes.length; i++) {
        const { from, to } = filteredTimes[i];
        const toDec = convertTimesToDecimal([from, to]);
        times.push(...generateTimes(toDec[0], toDec[1]));
      }
      return times;
    }
    return [];
  }, [data.openingExceptions, data.openingTimes, generateTimes, values.date]);

  const DesktopView = useMemo(
    () => (
      <div style={{ width: "5000px" }}>
        <div className="row">
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Cognome*</label>
              </div>
              <div className="col-auto">
                <input
                  required
                  type="text"
                  className="form-control bg-primary text-white"
                  name="surname"
                  value={values.surname}
                  onChange={handleChangeValues}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 align-items-end">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Nome*</label>
              </div>
              <div className="col-auto">
                <input
                  required
                  type="text"
                  className="form-control bg-primary text-white"
                  name="nome"
                  value={values.nome}
                  onChange={handleChangeValues}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Email*</label>
              </div>
              <div className="col-auto">
                <input
                  required
                  type="email"
                  className="form-control bg-primary text-white"
                  name="email"
                  value={values.email}
                  onChange={handleChangeValues}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Telefono cellulare*</label>
              </div>
              <div className="col-auto">
                <input
                  required
                  type="text"
                  className="form-control bg-primary text-white"
                  name="phone"
                  value={values.phone}
                  onChange={handleChangeValues}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Numero coperti*</label>
              </div>
              <div className="col-auto">
                <select
                  required
                  className="form-control bg-primary text-white"
                  name="seats"
                  value={values.seats}
                  onChange={handleChangeValues}
                >
                  {Array.from({ length: 10 }, (_, i) => (
                    <option key={`seats-${i}`} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Numero bambini</label>
              </div>
              <div className="col-auto">
                <select
                  className="form-control bg-primary text-white"
                  name="children"
                  value={values.children}
                  onChange={handleChangeValues}
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <option key={`kids-${i}`} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Giorno*</label>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-md-4">
                    <DatePicker
                      locale="it"
                      name="date"
                      className="form-control bg-primary text-white"
                      dateFormat="dd/MM/yyyy"
                      selected={values.date}
                      onChange={date => date && setValues({ ...values, date })}
                      onChangeRaw={e => {
                        e.preventDefault();
                      }}
                      required
                      filterDate={hasTimes}
                      minDate={today}
                      showDisabledMonthNavigation
                      placeholderText="Data Prenotazione"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row g-3 m-2 justify-content-end">
              <div className="col-auto">
                <label className="col-form-label">Fascia oraria*</label>
              </div>
              <div className="col-auto">
                <select
                  required
                  className="form-control bg-primary text-white"
                  name="time"
                  value={values.time}
                  onChange={handleChangeValues}
                >
                  {getTimes()}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 m-2">
          <div className="col">
            <label className="col-form-label">
              Intolleranze/allergie alimentari e/o note
            </label>
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control bg-primary text-white"
              onChange={handleChangeValues}
              value={values.notes}
              name="notes"
            />
          </div>
        </div>

        <div className="pt-4 pb-4">
          <div className="float-end">
            <Button
              style={{ opacity: isDisabled ? 0.5 : 1 }}
              disabled={isDisabled}
              type="submit"
              className="bg-secondary text-white me-3"
              callback={() => handleOnSubmit}
            >
              INVIA
            </Button>
          </div>
        </div>
        <div className="pt-3">
          <p className="mb-1">*Campi obbligatori</p>
          <p>
            Completando l’operazione si dichiara di accettare le{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => download("../termini-e-condizioni.pdf")}
            >
              <u>condizioni d’uso</u>
            </span>{" "}
            e l’
            <span
              className="cursor-pointer text-primary"
              onClick={() => download("../privacy-e-policy.pdf")}
            >
              <u>informativa Privacy</u>
            </span>
          </p>
        </div>
      </div>
    ),
    [getTimes, handleChangeValues, handleOnSubmit, hasTimes, isDisabled, values]
  );

  const MobileView = useMemo(
    () => (
      <div>
        <div className="row">
          <div className="col-12 mb-2">
            <label className="col-form-label">Cognome*</label>
            <input
              required
              type="text"
              className="form-control bg-primary text-white"
              name="surname"
              value={values.surname}
              onChange={handleChangeValues}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Nome*</label>
            <input
              required
              type="text"
              className="form-control bg-primary text-white"
              name="nome"
              value={values.nome}
              onChange={handleChangeValues}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Email*</label>
            <input
              required
              type="email"
              className="form-control bg-primary text-white"
              name="email"
              value={values.email}
              onChange={handleChangeValues}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Telefono cellulare*</label>
            <input
              required
              type="text"
              className="form-control bg-primary text-white"
              name="phone"
              value={values.phone}
              onChange={handleChangeValues}
            />
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Numero coperti*</label>
            <select
              required
              className="form-control bg-primary text-white"
              name="seats"
              value={values.seats}
              onChange={handleChangeValues}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <option key={`seats-${i}`} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Numero bambini</label>
            <select
              className="form-control bg-primary text-white"
              name="children"
              value={values.children}
              onChange={handleChangeValues}
            >
              {Array.from({ length: 5 }, (_, i) => (
                <option key={`kids-${i}`} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 mb-2">
            <label className="col-form-label">Giorno*</label>
            <DatePicker
              locale="it"
              name="date"
              className="form-control bg-primary text-white"
              dateFormat="dd/MM/yyyy"
              selected={values.date}
              onChange={date => date && setValues({ ...values, date })}
              onChangeRaw={e => {
                e.preventDefault();
              }}
              required
              filterDate={hasTimes}
              minDate={today}
              showDisabledMonthNavigation
              placeholderText="Data Prenotazione"
            />
          </div>
        </div>
        <div className="col-12 mb-2">
          <label className="col-form-label">Fascia oraria*</label>
          <select
            className="form-control bg-primary text-white"
            name="time"
            value={values.time}
            onChange={handleChangeValues}
          >
            {getTimes()}
          </select>
        </div>
        <div className="col-12 mb-2">
          <label className="col-form-label">
            Intolleranze/allergie alimentari e/o note
          </label>
          <input
            type="text"
            className="form-control bg-primary text-white"
            onChange={handleChangeValues}
            value={values.notes}
            name="notes"
          />
        </div>

        <div className="pt-4 pb-4">
          <Button
            style={{ opacity: isDisabled ? 0.5 : 1 }}
            disabled={isDisabled}
            type="submit"
            className="bg-secondary text-white w-100"
          >
            INVIA
          </Button>
        </div>
        <div className="pt-3">
          <p className="mb-1">*Campi obbligatori</p>
          <p>
            Completando l’operazione si dichiara di accettare le{" "}
            <span
              className="cursor-pointer text-primary"
              onClick={() => download("../termini-e-condizioni.pdf")}
            >
              <u>condizioni d’uso</u>
            </span>{" "}
            e l’
            <span
              className="cursor-pointer text-primary"
              onClick={() => download("../privacy-e-policy.pdf")}
            >
              <u>informativa Privacy</u>
            </span>
          </p>
        </div>
      </div>
    ),
    [getTimes, handleChangeValues, hasTimes, isDisabled, values]
  );

  return (
    <main>
      <Helmet title="Prenota - Ristorante A Mangiare" defer={false} />
      <Layout className="bg-white relative">
        <div className="bg-secondary p-1 p-lg-5">
          <div className="container">
            <div className="text-center">
              <h1 className="text-primary text-uppercase mt-5 mt-lg-0 mb-0">
                Prenota il tuo tavolo
              </h1>
              <h2 className="text-white">vivi con noi un’esperienza unica</h2>
            </div>
            <div className="form-container bg-white p-4 mt-5 mb-5">
              <form onSubmit={e => handleOnSubmit(e)}>
                <div className="d-none d-xl-flex">{DesktopView}</div>
                <div className="d-xl-none">{MobileView}</div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      <PopupComponent />
    </main>
  );
};
export default Prenota;

const num2Time = (time: number) => {
  const hours = Math.floor(time);
  const minutes = (time - hours) * 60;
  return `${hours}:${minutes === 0 ? "00" : minutes}`;
};

const convertTimesToDecimal = (times: string[]): number[] => {
  return times.map(time => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours + minutes / 60;
  });
};

export { num2Time, convertTimesToDecimal };

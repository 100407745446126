import React from 'react';

interface OutcomeIconProps {
  outcome: 'success' | 'error';
  color?: string;
  backgroundColor?: string;
  size?: number;
}

export const OutcomeIcon = ({
  outcome,
  color,
  backgroundColor = 'white',
  size = 128,
}: OutcomeIconProps) => {
  return outcome === 'success' ? (
    <svg version="1.1" viewBox="0 0 85.222 85.319" width={size} height={size}>
      <path
        d="m35.982 84.904c-17.943-2.8206-32.4-17.013-35.505-34.856-0.6361-3.6548-0.6358-10.671 6.14e-4 -14.598 3.4009-20.986 22.533-36.386 43.985-35.405 11.07 0.50626 20.491 4.6669 28.331 12.512 8.2878 8.2928 12.429 18.319 12.429 30.093 0 11.891-4.1507 21.871-12.577 30.24-6.6338 6.5888-14.353 10.547-23.383 11.991-3.5986 0.57542-9.6983 0.58596-13.28 0.02294z"
        fill={backgroundColor}
        strokeWidth=".32"
      />
      <path
        d="m31.328 62.086c0.78396-1.3618 2.7447-4.4434 4.3572-6.8479 8.5376-12.731 18.965-23.867 29.897-31.929 1.496-1.1033 2.768-2.1348 2.8267-2.2924 0.19157-0.51442-2.1625-0.30128-4.1968 0.37998-8.997 3.013-18.313 10.062-29.372 22.224-2.1549 2.37-4.0756 4.3067-4.2682 4.3037-0.19259-0.0029-0.70153-0.81669-1.131-1.8082-1.6293-3.7618-4.7881-6.4941-7.5182-6.5032-1.4775-0.0049-3.5481 1.1743-4.4469 2.5325l-0.81541 1.2322 0.91442 0.18288c0.50293 0.10059 1.4212 0.62783 2.0406 1.1717 1.649 1.4478 3.1411 4.7122 8.08 17.678 1.1484 3.0146 1.7401 2.9619 3.6316-0.32392z"
        fill={color ?? '#ce953f'}
      />
    </svg>
  ) : (
    <svg version="1.1" viewBox="0 0 84.003 84.032" width={size} height={size}>
      <path
        d="m49.831 83.38c13.091-2.5795 24.441-11.362 30.114-23.302 4.5623-9.6028 5.3057-20.534 2.0889-30.717-3.9003-12.347-13.7-22.489-26.035-26.945-5.6747-2.0502-13.575-2.922-19.223-2.1214-16.438 2.3303-29.52 13.119-34.701 28.617-1.4762 4.4158-2.0764 8.1685-2.0748 12.973 4e-3 12.498 4.9571 23.471 14.369 31.834 5.6504 5.0206 13.574 8.7255 21.31 9.9634 3.5693 0.57122 10.467 0.4238 14.153-0.30248z"
        fill={backgroundColor}
        strokeWidth=".32"
      />
      <path
        d="m28.094 63.02c4.0304-7.2675 13.592-20.457 14.83-20.457 1.3216 0 11.285 10.663 16.23 17.37 2.4754 3.3572 3.2113 3.3576 1.7925 9.6e-4 -2.4581-5.8157-7.1262-14.042-11.015-19.411-1.2392-1.7109-2.2531-3.3487-2.2531-3.6396 0-1.0501 9.6165-9.8374 15.672-14.321 2.848-2.1085 2.9708-2.2463 2.0921-2.3475-1.3407-0.15438-12.003 5.2466-17.541 8.8855-2.4292 1.5961-4.5198 2.902-4.646 2.902-0.12614 0-1.3067-0.94813-2.6234-2.107-6.0756-5.3471-13.209-7.9585-20.001-7.322-3.8296 0.35886-3.9802 0.98507-0.68076 2.8302 4.8874 2.7332 16.848 11.121 16.848 11.815 0 0.17381-1.1542 1.5183-2.5649 2.9877-5.6074 5.8408-8.0634 11.495-8.113 18.676-0.02605 3.7713 0.22128 5.12 0.93893 5.12 0.26883 0 0.73409-0.44232 1.0339-0.98294z"
        fill={color ?? '#4f4f4f'}
        strokeWidth=".32"
      />
    </svg>
  );
};

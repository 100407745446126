const today = new Date();

export const initialValues = {
  nome: "",
  surname: "",
  email: "",
  phone: "",
  seats: "1",
  children: "0",
  date: today,
  time: "12:30",
  notes: "",
};

import React from 'react';
import { Modal } from 'react-bootstrap';

interface PopupProps {
  isOpen: boolean;
  style?: React.CSSProperties;
  headerClass?: string;
  bodyClass?: string;
  onClose: () => void;
  content: React.ReactNode;
}

export const usePopup = ({
  isOpen,
  headerClass,
  bodyClass,
  onClose,
  content,
}: PopupProps) => {
  const handleClose = () => {
    onClose();
  };

  const r = 20;

  const PopupComponent = () => {
    return (
      <Modal
        size="lg"
        dialogClassName={`br-${r}`}
        contentClassName={`br-${r} bg-transparent`}
        centered
        show={isOpen}
        onHide={handleClose}
      >
        <Modal.Header className={`${headerClass} brt-${r}`} closeButton />
        <Modal.Body className={`${bodyClass} brb-${r}`}>{content}</Modal.Body>
      </Modal>
    );
  };

  return { PopupComponent };
};
